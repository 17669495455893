
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import PaginationUtill from "@/views/util/PaginationUtill";
export default defineComponent({
  name: "DataTable",

  components: {
    TableTab,
  },
  setup() {
    let accountId = store.getters.AccountId;
    const tableValuesGas = ref([]);
    const tableValuesElec = ref([]);
    const addition = ref(false);
    const removal = ref(false);

    const loading = ref<boolean>(false);
    interface Tableheader {
      name: string;
      key: string;
      sortable: boolean;
    }

    const pageheaderGas = "Asset Register List -Gas";
    const TableHeadersGas = ref<Array<Tableheader>>();
    const OriginalTableHeadersGas = ref<Array<Tableheader>>();
    const colnamesGas = ref();
    const pageheaderElec = "Asset Register List- Electricity";
    const TableHeadersElec = ref<Array<Tableheader>>();
    const OriginalTableHeadersElec = ref<Array<Tableheader>>();
    const colnamesElec = ref();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Asset Register List", ["NA"]);

      addition.value = false;
      removal.value = false;
    });

    loading.value = true;
    let domainName = store.getters.DomainName;
    const accountInfo: any = {
      accountid: accountId,
      stIndx: 1,
      enIndx: 500,
      DomainName: domainName,
    };

    ApiService.post(
      "api/AssetRegisterSupplier/assetRegisterSupplier",
      accountInfo
    )
      .then((resp) => {
        colnamesGas.value = PaginationUtill.getGasColumn.map((a) => a.key);

        OriginalTableHeadersGas.value = PaginationUtill.getGasColumn.map(
          (a) => ({
            name: a.name,
            key: a.key,
            sortable: true,
          })
        );
        TableHeadersGas.value = PaginationUtill.getGasColumn.map((a) => ({
          name: a.name,
          key: a.key,
          sortable: true,
        }));

        tableValuesGas.value = resp.data.assetreggas.map((a) => a);

        colnamesElec.value = PaginationUtill.getElecColumn.map((a) => a.key);

        OriginalTableHeadersElec.value = PaginationUtill.getElecColumn.map(
          (a) => ({
            name: a.name,
            key: a.key,
            sortable: true,
          })
        );
        TableHeadersElec.value = PaginationUtill.getElecColumn.map((a) => ({
          name: a.name,
          key: a.key,
          sortable: true,
        }));

        tableValuesElec.value = resp.data.assetregelec.map((a) => a);

        loading.value = false;
        addition.value = true;
      })
      .catch((a) => {
        loading.value = false;
        console.log("API Error", a);
      });

    function openAssetAddition() {
      addition.value = true;
      removal.value = false;
    }
    function openAssetRemoval() {
      addition.value = false;
      removal.value = true;
    }


    const downloadurl = "api/AssetRegisterSupplier/downloadFile";
    const downloadprops = accountInfo;
    return {
      downloadurl,
      downloadprops,
      pageheaderElec,
      pageheaderGas,
      colnamesGas,
      colnamesElec,
      openAssetAddition,
      openAssetRemoval,
      addition,
      removal,
      tableValuesGas,
      tableValuesElec,
      loading,
      OriginalTableHeadersGas,
      OriginalTableHeadersElec,
    };
  },
});
